export async function checkEmail(email) {
  let data = [];
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}auth/check-email?email=${email}`,
    );
    if (res.ok) {
      data = await res.json();
      return { data }
    } else {
      const error = await res.json();
      return { error }
    }
  } catch (error) {
    return { 'error': error }
  }
};

export async function switchUserRole(accessToken, email, expectedRole) {
  let data = [];
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}auth/switch-user-role?expectedRole=${expectedRole}&email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // return res;
    if (res.ok) {
      data = await res.json();
      return { data }
    } else {
      const error = await res.json();
      return { error }
    }
  } catch (error) {
    return { error: error.message || 'An unexpected error occurred' };
  }
};